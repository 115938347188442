const Size = {
    SMALL: '5px',
    MEDIUM: '10px'
}
const breakpoints = [545, 800, 992, 1200]
const mq = breakpoints.map(
    bp => `@media (min-width: ${bp}px)`
  )
const theme = {
    Size, mq
}
export default theme