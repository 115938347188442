import styled from '@emotion/styled'


export const Top = styled.div`
`
export const HomeContainer = styled.div`
    display: flex;
    flex-flow: column;
    height:100%;
`
export const CompanyTitle = styled.div`
    font-family: 'Fairplay Display';
    font-size: 65px;
`
export const CompanySubTitle = styled.div`
    font-family: 'Fairplay Display';
    font-size: 32px;
`
export const DescriptionInfo = styled.div`
    font-size: 16px;
`
export const SectionContainerText = styled.div`
    text-align:center;
    `
export const SectionContainer = styled.div`
    display:flex;
    position:relative;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    background-image: url("ashkan-forouzani-photo.jpg");
    background-repeat: no-repeat;
    background-size:cover;
`
export const TopHeader = styled.div`
    flex: 0 1 auto;
`
export const Footer = styled.div`
    flex: 0 1 auto;
`
export const DescriptionListContainer = styled.div`
    position:absolute;
    top:0px;
    left:0px;

`