import React from 'react'
import PropTypes from 'prop-types'
import {HeaderContainer, Title, Email, Phone, TextHolder} from './Header.styles'

const Header = props => {
  const {title, email, phone, theme='light'} = props;
    return <HeaderContainer theme={theme}>
    <TextHolder theme={theme}>
      <Title>{title}</Title>
      <Email>{email}</Email>
      <Phone>{phone}</Phone>
    </TextHolder>
    </HeaderContainer>
}
Header.propTypes = {
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    theme: PropTypes.string,
}

export default Header