import React from 'react'
import PropTypes from 'prop-types'

const About = props => {
    return <div>About</div>
}
About.propTypes = {
    selected: PropTypes.string
}

export default About