import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import About from './pages/about/About'
import Home from './pages/home/Home'

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/about" element={<About/>}>
          </Route>
          <Route path="/" element={<Home/>}>
          </Route>
        </Routes>
     </Router>
  );
}

export default App;
