import React from 'react'
import PropTypes from 'prop-types'
import {SectionContainer, HomeContainer, TopHeader, Footer, SectionContainerText, DescriptionListContainer} from './Home.styles'
import Header from '../../components/header/Header'
import SAMFooter from '../../components/footer/SAMFooter'
import DescriptionList from '../../components/list/DescriptionList'


const Home = props => {
    return <HomeContainer>
        <TopHeader>
        <Header
            title='JAMMITT LLC'
            email='info@jammitt.com'
            phone='402-401-4454'
            theme='light'
            superTitle="Welcome"
            superSubTitle='Everything Starts Here'
        />
       
        </TopHeader>
        
        <SectionContainer>
            <DescriptionListContainer>
            <DescriptionList list={['mobile', 'web', 'APIs','databases', 'security', 'AWS cloud']} />
            </DescriptionListContainer> 
            <SectionContainerText>
            SOLUTIONS PROVIDER AND CONSULTANT SERVICES
            </SectionContainerText>
        </SectionContainer>
        <Footer>
        <SAMFooter />
        </Footer>
    </HomeContainer>
}
Home.propTypes = {
    selected: PropTypes.string
}

export default Home