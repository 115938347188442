import React from 'react'
import PropTypes from 'prop-types'
import {HeaderContainer, Item, TextHolder} from './DescriptionList.styles'

const getList = list => {
    return list.map((item, idx) => {
        return <Item key={idx}>{item}</Item>
    })
}
const DescriptionList = props => {
  const {list, theme='light'} = props;
    return <HeaderContainer theme={theme}>
    <TextHolder theme={theme}>
      <div>{getList(list)}</div>
    </TextHolder>
    </HeaderContainer>
}
DescriptionList.propTypes = {
    list: PropTypes.array,
    theme: PropTypes.string
}

export default DescriptionList