import styled from '@emotion/styled'
import theme from '../../theme'

export const HeaderContainer = styled.div(props => ({
    color: props.theme === 'light' ? '#2F4F4F' : '#FFFAFA',
    fontFamily: 'Fairplay Display',
    position:'relative',
    minHeight: '100px',
    width: '100%',
    paddingTop: '5px',
    paddingLeft: '5px',
}))
export const TextHolder = styled.div`
    width: 100%;
    position: absolute;
    display:flex;
    flex-direction: column;
    bottom: 20px;
`
export const Email = styled.div`
font-size: 18px;
margin-left: 10px;
`
export const Phone = styled.div`
font-size: 18px;
margin-left: 10px;
`
export const Title = styled.div`
font-size: 32px;
margin-left: 10px;
`

export const Image = styled.img`
    width: 100%;
    max-height: 450px;
`
export const SuperTextHolder = styled.div`
  position: absolute;
  width: 100%;
  display: none;
  ${theme.mq[0]}{
    display: flex;
}
  left: 0;
  right: 0;
  align-items: center;
  top: 0;
  bottom: 0;
  justify-content: center;
  flex-direction: column;
  
`
export const SuperTitle = styled.div`
font-size: 42px;
`
export const SuperSubTitle = styled.div`
    font-size: 32px;
`