import styled from '@emotion/styled'

export const HeaderContainer = styled.div(props => ({
    paddingLeft: '5px',
}))
export const TextHolder = styled.div(props => ({
    width: '100%',
}))
export const Title = styled.div`
font-family: 'Fairplay Display';
font-size: 32px;
margin-left: 10px;
`
export const Item = styled.div`
`